// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/CAjjxbTJBxHwH1MagCef/Phosphor.js";
const PhosphorFonts = getFonts(Phosphor);

const serializationHash = "framer-vCWvp"

const variantClassNames = {f3o5IKHT6: "framer-v-1u3j5z6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({background, height, id, width, ...props}) => { return {...props, sXnGWmwC_: background ?? props.sXnGWmwC_ ?? "var(--token-cd708a3c-25bb-4606-92f4-351c74f13945, rgb(87, 72, 72))"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;background?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, sXnGWmwC_, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "f3o5IKHT6", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1u3j5z6", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"f3o5IKHT6"} ref={ref ?? ref1} style={{backgroundColor: sXnGWmwC_, ...style}}><ComponentViewportProvider ><motion.div className={"framer-qw0jyq-container"} layoutDependency={layoutDependency} layoutId={"ZYZoYoDu4-container"}><Phosphor color={"var(--token-86117d10-82c0-48ca-83b9-ad514639102d, rgb(45, 31, 233))"} height={"100%"} iconSearch={"House"} iconSelection={"ArrowDown"} id={"ZYZoYoDu4"} layoutId={"ZYZoYoDu4"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"light"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vCWvp.framer-duugcl, .framer-vCWvp .framer-duugcl { display: block; }", ".framer-vCWvp.framer-1u3j5z6 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 36px 64px 36px 64px; position: relative; width: 1896px; }", ".framer-vCWvp .framer-qw0jyq-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vCWvp.framer-1u3j5z6 { gap: 0px; } .framer-vCWvp.framer-1u3j5z6 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-vCWvp.framer-1u3j5z6 > :first-child { margin-top: 0px; } .framer-vCWvp.framer-1u3j5z6 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 92
 * @framerIntrinsicWidth 1896
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"sXnGWmwC_":"background"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerH2DE4ix3n: React.ComponentType<Props> = withCSS(Component, css, "framer-vCWvp") as typeof Component;
export default FramerH2DE4ix3n;

FramerH2DE4ix3n.displayName = "Scroll Bar";

FramerH2DE4ix3n.defaultProps = {height: 92, width: 1896};

addPropertyControls(FramerH2DE4ix3n, {sXnGWmwC_: {defaultValue: "var(--token-cd708a3c-25bb-4606-92f4-351c74f13945, rgb(87, 72, 72)) /* {\"name\":\"hero text\"} */", title: "Background", type: ControlType.Color}} as any)

addFonts(FramerH2DE4ix3n, [{explicitInter: true, fonts: []}, ...PhosphorFonts], {supportsExplicitInterCodegen: true})